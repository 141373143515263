import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {
  AlertsProvider,
  configureRaygun,
  MiSuiteJurisdiction,
  MiSuiteRole,
  ssoUtils,
  User,
  UserProvider
} from '@reasoncorp/kyber-js';

import packageJson from '../package.json';
import './index.scss';
import App from './App';
import config from './config';
import {CertsAppProvider} from './contexts';
import {ssoApi} from './api';

if (config.envName !== 'prod') {
  document.title = `${config.envName.toUpperCase()} - ${document.title}`;
}

const raygunOptions = {
  apiKey: process.env.REACT_APP_RAYGUN_API_KEY || 'apikeygoeshere',
  version: packageJson.version,
  tags: [config.envName]
};

configureRaygun(raygunOptions);

const setPermissions = (currentUser?: User) => ({
  isAdmin: ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.CERTS_APP, MiSuiteRole.ADMIN),
  isDepartmentalTech: ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.CERTS_APP, MiSuiteRole.DEPARTMENTAL_TECHNICIAN),
  isCertifiedUser: currentUser ? currentUser.certifiedUser : false
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <UserProvider ssoApi={ssoApi} setPermissions={setPermissions}>
      <CertsAppProvider>
        <AlertsProvider>
          <App/>
        </AlertsProvider>
      </CertsAppProvider>
    </UserProvider>
  </BrowserRouter>
);