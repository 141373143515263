import {memo, useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {FormikFileInput, FormikSelect, StatefulButton, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {courseApi, loggedCourseApi} from '../../api';
import {logCourseFormSchema} from '../../schema';
import {CertificationStatus, CertificationYear, Course, LoggedCourse} from '../../types';
import {LogCourseFormFields} from '../../types/forms';

type Props = {
  isOpen: boolean
  certificationYear: CertificationYear
  certificationStatus: CertificationStatus
  onToggle: (loggedCourse?: LoggedCourse) => void
}

const LogCourseModal = ({
                          isOpen,
                          certificationYear,
                          certificationStatus,
                          onToggle
                        }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [coursesToDisplay, setCoursesToDisplay] = useState<Course[]>([]);
  const initialValues: LogCourseFormFields = {courseId: -1, proofOfCompletionFile: undefined};

  const handleSubmit = useCallback(async (values: LogCourseFormFields, formikHelpers: FormikHelpers<LogCourseFormFields>) => {
    try {
      const logCourseRequest = new FormData();
      logCourseRequest.append('courseId', values.courseId.toString());
      logCourseRequest.append('proofOfCompletionFile', values.proofOfCompletionFile as File);

      const loggedCourse = await loggedCourseApi.create(logCourseRequest);
      formikHelpers.setSubmitting(false);
      showSuccessAlert(messages.LOGGED_COURSE_SUCCESSFUL);
      onToggle(loggedCourse);
    } catch (error) {
      formikHelpers.setSubmitting(false);
      showErrorAlert(messages.LOGGED_COURSE_FAILED);
      onToggle();
    }
  }, [onToggle, showErrorAlert, showSuccessAlert]);

  const handleClose = useCallback((formikProps: FormikProps<LogCourseFormFields>) => {
    formikProps.resetForm();
    onToggle();
  }, [onToggle]);

  useEffect(() => {
    const loadCourses = async () => {
      if (certificationYear && certificationYear.loggingCoursesAllowed) {
        try {
          const courses = await courseApi.findAllBy(certificationYear.value, false);
          const loggedCourseIds = certificationStatus.loggedCourses
            .map(loggedCourse => loggedCourse.course.id);
          const coursesToDisplay = courses
            .filter(course => !loggedCourseIds.includes(course.id));
          setCoursesToDisplay(coursesToDisplay);
        } catch (error) {
          showErrorAlert(messages.COURSES_LOAD_FAILED);
        }
      }
    };

    void loadCourses();
  }, [certificationYear, certificationStatus, showErrorAlert]);

  const renderCourseOption = useCallback(({
                                            id,
                                            name,
                                            courseNumber,
                                            instructor,
                                            courseDateDisplay
                                          }: Course) => {
    const dropdownValue = `${name} ${courseNumber} - ${instructor} - ${courseDateDisplay === null ? 'N/A' : courseDateDisplay}`;
    return <option key={id}
                   value={id}>
      {dropdownValue}
    </option>;
  }, []);

  return (
    <Formik initialValues={initialValues}
            validationSchema={logCourseFormSchema}
            onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Modal className="LogCourseModal"
                 autoFocus={false}
                 backdrop="static"
                 isOpen={isOpen}
                 size="lg"
                 toggle={() => handleClose(formikProps)}>
            <ModalHeader toggle={() => handleClose(formikProps)}>
              Log Continuing Education Course
            </ModalHeader>
            <Form onSubmit={formikProps.handleSubmit}
                  autoComplete="off">
              <ModalBody>
                <Row>
                  <Col>
                    <FormikSelect name="courseId"
                                  labelText="Select Course"
                                  autoFocus
                                  aria-required={true}>
                      <option value="">Select</option>
                      {coursesToDisplay.map(renderCourseOption)}
                    </FormikSelect>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormikFileInput name="proofOfCompletionFile"
                                     labelText="Upload Proof of Completion"/>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <StatefulButton activeState={formikProps.isSubmitting ? 'submitting' : 'default'}
                                states={[
                                  {name: 'default', text: 'Log Course'},
                                  {name: 'submitting', text: 'Logging Course', icon: 'spinner', spinIcon: true}
                                ]}
                                color="success"
                                onClick={formikProps.submitForm}
                                disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                </StatefulButton>
                <Button color="secondary"
                        onClick={() => handleClose(formikProps)}
                        disabled={formikProps.isSubmitting}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(LogCourseModal);