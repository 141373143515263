import {memo, useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {FormikSelect, ProgressIndicator, useAlerts, User} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {certsUserApi, loggedCourseApi} from '../../api';
import {Course, LogOnBehalfOfFormFields} from '../../types';
import {logOnBehalfOfFormSchema} from '../../schema';

type Props = {
  isOpen: boolean
  course: Course
  onToggle: (course?: Course) => void
}
const LogOnBehalfOfModal = ({
                              isOpen,
                              course,
                              onToggle
                            }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [loggableUsers, setLoggableUsers] = useState<User[]>([]);
  const initialValues = {courseId: course.id, certsUserId: -1};

  const handleSubmit = useCallback(async (values: LogOnBehalfOfFormFields,
                                          formikHelpers: FormikHelpers<LogOnBehalfOfFormFields>) => {
    try {
      await loggedCourseApi.logOnBehalfOf(values);
      formikHelpers.setSubmitting(false);
      showSuccessAlert(messages.LOGGED_COURSE_SUCCESSFUL);
      onToggle(course);
    } catch (error) {
      formikHelpers.setSubmitting(false);
      showErrorAlert(messages.LOGGED_COURSE_FAILED);
      onToggle();
    }
  }, [course, onToggle, showErrorAlert, showSuccessAlert]);

  const handleClose = useCallback((formikProps: FormikProps<LogOnBehalfOfFormFields>) => {
    formikProps.resetForm();
    onToggle();
  }, [onToggle]);

  useEffect(() => {
    const loadLoggableUsers = async () => {
      try {
        if (isOpen) {
          const loggableUsers = await certsUserApi.findLoggableUsers(course.id);
          setLoggableUsers(loggableUsers);
          setLoadingState(loadingState => ({...loadingState, loading: false}));
        }
      } catch (error) {
        showErrorAlert(messages.USER_LOAD_FAILED);
        setLoadingState(loadingState => ({...loadingState, loading: false, loadError: true}));
      }
    };
    void loadLoggableUsers();
  }, [isOpen, course.id, showErrorAlert]);

  const renderLoggableUserOption = useCallback(({id, fullName, certificationNumber}: User) =>
    <option key={id}
            value={id}>
      {fullName} - {certificationNumber}
    </option>, []);

  return (
    <Formik initialValues={initialValues}
            enableReinitialize
            validationSchema={logOnBehalfOfFormSchema}
            onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Modal isOpen={isOpen}
                 autoFocus={false}
                 backdrop="static"
                 size="lg"
                 className="LogOnBehalfOfModal"
                 toggle={() => handleClose(formikProps)}>
            <ModalHeader toggle={() => handleClose(formikProps)}>Log User</ModalHeader>
            <Form onSubmit={formikProps.handleSubmit}
                  autoComplete="off">
              <ModalBody>
                {loadingState.loading && <ProgressIndicator/>}
                {!loadingState.loading && <Row>
                  <Col>
                    <FormikSelect ariaLabel="Select User"
                                  name="certsUserId"
                                  labelText="Select User"
                                  autoFocus
                                  aria-required={true}>
                      <option value="">Select</option>
                      {loggableUsers.map(renderLoggableUserOption)}
                    </FormikSelect>
                  </Col>
                </Row>}
              </ModalBody>
              <ModalFooter>
                <Button color="success"
                        onClick={formikProps.submitForm}
                        disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => handleClose(formikProps)}
                        disabled={formikProps.isSubmitting}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(LogOnBehalfOfModal);